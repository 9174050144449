import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Twin House, Bikaner",
  address: "Bikaner",
  client: "Kamal Arora",
  area: "8000 Sq. Ft.",
  project: "Private Residence",
  category: "Residential | Architecture | House",
  status: "Completed",
  backlink: "/projects/residential/",
};

export default class Projecttwinhousebikaner extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Residential Projects | Twin House, Bikaner"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the architecture and design of a spacious private residence developed over 8000 sq. ft."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
