const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/twinhouse/1.png",
    text: "1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/twinhouse/2.png",
    text: "2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/twinhouse/3.png",
    text: "3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/twinhouse/4.png",
    text: "4",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/twinhouse/5.png",
    text: "5",
  },
];

export default data;
